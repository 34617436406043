import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '../context/stateContext'
import { motion } from 'framer-motion'
import {kLOCALES} from "../constants";
import {useHistory} from "react-router-dom";

const Container = styled(motion.div)`
	${tw`absolute`}
`
const Wrap = styled.div`
	${tw`container flex justify-between items-center mx-auto h-full`}
`
const LangItem = styled.a`
	${tw`mx-1 text-sm md:text-base border-b border-transparent transition duration-500 ease-in-out cursor-pointer`}
	&.active {
		${tw`font-bold border-b border-black border-black cursor-default`}
	}
`

const LangSwitcher = ({ mode }) => {
	const stateContext = useStateContext()
	const history = useHistory();

	const spacerVariants = {
		init: {
			y: '-200%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: '-200%',
			transition: {
				duration: 0.3,
			},
		},
	}

	const switchLanguage = (locale) => {
		history.push(`/?lang=${ locale }`);
		stateContext.dispatch({ action: 'SET_LOCALE', data: locale });
	}

	return (
		<Container className={mode === 'mobile' ? 'top-4 left-16 z-10 md:hidden' : 'hidden md:block top-6 right-10'} variants={spacerVariants} initial='init' animate='animate' exit='exit'>
			<Wrap>
				{ kLOCALES.map(locale => (
					<LangItem key={ locale } className={stateContext.state.locale === locale ? 'active' : ''} onClick={() => switchLanguage(locale)}>
						{ locale.toUpperCase() }
					</LangItem>
				)) }
			</Wrap>
		</Container>
	)
}

export default LangSwitcher
