import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '../context/stateContext'
import logo from './../assets/logo.svg'
import homeIcon from './../assets/home.svg'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
	${tw`h-20 shadow-md absolute top-0 left-0 right-0 w-full bg-white z-10`}
`
const Wrap = styled.div`
	${tw`flex justify-between items-center mx-auto h-full px-6`}
`
const Home = styled.div`
	${tw`flex items-center justify-center`}
`
const Logo = styled.div`
	${tw`flex items-center justify-center w-40`}
`
const Button = styled.div`
	${tw`cursor-pointer`}
`

const TopBar = ({ userStatus }) => {
	const stateContext = useStateContext()

	const togglePopup = () => {
		stateContext.dispatch({ action: 'SET_POPUP_STATUS', data: true })
	}

	const topBarVariants = {
		init: {
			y: '-120%',
			transition: {
				duration: 0.7,
			},
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.6,
			},
		},
		exit: {
			y: '-120%',
			transition: {
				duration: 0.7,
			},
		},
	}

	return (
		<Container variants={topBarVariants} initial='init' animate='animate' exit='exit'>
			<Wrap>
				<Home>
					<Button onClick={togglePopup}>
						<img src={homeIcon} alt='' />
					</Button>
				</Home>
				<Logo>
					<img src={logo} alt='' />
				</Logo>
			</Wrap>
		</Container>
	)
}

export default TopBar
