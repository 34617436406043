import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import Board from './Board'
import Welcome from './Welcome'
import EndScreen from './EndScreen'
import { useStateContext } from './../context/stateContext'
import { AnimatePresence } from 'framer-motion'

const Container = styled.div`
	${tw`h-full w-full flex justify-center items-center`}
`

const Screen = () => {
	const stateContext = useStateContext()
	const { loadSounds } = useStateContext()

	useEffect(() => {
		if (!stateContext.state.soundLoaded) {
			loadSounds()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {}, [stateContext.state.gameStatus])
	return (
		<Container>
			<AnimatePresence exitBeforeEnter initial={false}>
				{stateContext.state.gameStatus === 'beforeInit' && <Welcome key='welcome' />}
				{stateContext.state.gameStatus === 'gameStart' && <Board key='board' />}
				{stateContext.state.gameStatus === 'gameEnded' && <EndScreen key='endscreen' />}
			</AnimatePresence>
		</Container>
	)
}

export default Screen
