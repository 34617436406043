import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '../context/stateContext'
import content from './../data/content.json'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
	${tw`fixed left-0 right-0 top-0 bottom-0 z-50 bg-white`}
`
const Wrap = styled.div`
	${tw`absolute flex flex-col justify-center items-center top-1/2 left-1/2 bg-white transform -translate-x-1/2 -translate-y-1/2 w-[440px] min-h-[460px] rounded-xl`}
`
const Alert = styled.p`
	${tw`text-3xl uppercase text-blue text-center font-semibold`}
`
const SvgWrap = styled.div`
	${tw`h-32 md:h-40 mb-10`}

	svg {
		${tw`h-full w-full`}
	}
`

const OrientationOverlay = ({ toggle }) => {
	const stateContext = useStateContext()
	const { locale } = stateContext.state
	const t = content.langs

	const containerVariants = {
		init: {
			opacity: 0,
			visibility: 'hidden',
			y: 20,
			transition: {
				duration: 0.7,
				type: 'spring',
			},
		},
		show: {
			opacity: 1,
			visibility: 'visible',
			y: 0,
			transition: {
				duration: 0.7,
				type: 'spring',
			},
		},
		exit: {
			y: 20,
			opacity: 0,
			transitionEnd: {
				visibility: 'hidden',
			},
			transition: {
				duration: 0.7,
				type: 'spring',
			},
		},
	}

	return (
		<Container variants={containerVariants} initial='init' animate='show' exit='exit'>
			<Wrap>
				<SvgWrap>
					<svg viewBox='0 0 76 82' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M38.3536 78.3536C38.5488 78.1583 38.5488 77.8417 38.3536 77.6464L35.1716 74.4645C34.9763 74.2692 34.6597 74.2692 34.4645 74.4645C34.2692 74.6597 34.2692 74.9763 34.4645 75.1716L37.2929 78L34.4645 80.8284C34.2692 81.0237 34.2692 81.3403 34.4645 81.5355C34.6597 81.7308 34.9763 81.7308 35.1716 81.5355L38.3536 78.3536ZM38 77.5C17.8416 77.5 1.5 61.1584 1.5 41H0.5C0.5 61.7107 17.2893 78.5 38 78.5V77.5Z'
							fill='#0069A4'
						/>
						<path
							d='M37.6464 3.64644C37.4512 3.84171 37.4512 4.15829 37.6464 4.35355L40.8284 7.53553C41.0237 7.73079 41.3403 7.73079 41.5355 7.53553C41.7308 7.34027 41.7308 7.02369 41.5355 6.82842L38.7071 4L41.5355 1.17157C41.7308 0.976308 41.7308 0.659726 41.5355 0.464463C41.3403 0.2692 41.0237 0.2692 40.8284 0.464463L37.6464 3.64644ZM38 4.5C58.1584 4.5 74.5 20.8416 74.5 41L75.5 41C75.5 20.2893 58.7107 3.5 38 3.5L38 4.5Z'
							fill='#0069A4'
						/>
						<rect x='27.5' y='20.5' width='22' height='41' rx='2.5' stroke='#0069A4' />
						<rect x='28' y='21' width='21' height='3' fill='#0069A4' />
						<rect x='28' y='55' width='21' height='6' fill='#0069A4' />
						<circle cx='38.5' cy='58.5' r='1.5' fill='white' />
					</svg>
				</SvgWrap>
				<Alert>{t[locale].orientationAlert}</Alert>
			</Wrap>
		</Container>
	)
}

export default OrientationOverlay
