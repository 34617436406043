import React from 'react'
import tw, { styled } from 'twin.macro'
import { motion } from 'framer-motion'
import { useStateContext } from '../context/stateContext'
import content from '../data/content.json'
import Sound from './Sound'

const Container = styled(motion.div)`
	${tw`h-20 shadow-mdTop fixed md:absolute bottom-0 left-0 right-0 w-full bg-white`}
`
const Wrap = styled.div`
	${tw`relative flex justify-between items-center mx-auto h-full px-6`}
`
const UserStatus = styled.div`
	${tw`container mx-auto flex justify-center`}
`
const Attribute = styled.div`
	${tw`flex mr-4`}
	& .name {
		${tw`uppercase`}
	}
	& span {
		${tw`font-bold mr-2`}
	}
`

const BottomGameBar = ({ userStatus }) => {
	const stateContext = useStateContext()
	const { locale } = stateContext.state
	const t = content.langs
	const { rounds, pairsCollected } = userStatus

	const bottomBarVariants = {
		init: {
			y: '120%',
			transition: {
				duration: 0.7,
			},
		},
		animate: {
			y: '0%',
			transition: {
				duration: 1,
			},
		},
		exit: {
			y: '120%',
			transition: {
				duration: 0.7,
			},
		},
	}

	return (
		<Container variants={bottomBarVariants} initial='init' animate='animate' exit='exit'>
			<Wrap>
				<UserStatus>
					<Attribute>
						<span>{pairsCollected}</span>
						<p className='name'>{pairsCollected === 1 ? t[locale].pairs[0] : t[locale].pairs[1]}</p>
					</Attribute>
					<Attribute>
						<span>{rounds}</span>
						<p className='name'>{rounds === 1 ? t[locale].rounds[0] : t[locale].rounds[1]}</p>
					</Attribute>
				</UserStatus>
			</Wrap>
			<Sound mode='game' />
		</Container>
	)
}

export default BottomGameBar
