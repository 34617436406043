import React, { useEffect, useCallback, useRef } from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '../context/stateContext'
import { motion } from 'framer-motion'
import bl01 from './../assets/blocks/bl_01.jpg'
import bl02 from './../assets/blocks/bl_02.jpg'
import bl03 from './../assets/blocks/bl_03.jpg'
import bl04 from './../assets/blocks/bl_04.jpg'
import bl05 from './../assets/blocks/bl_05.jpg'
import bl06 from './../assets/blocks/bl_06.jpg'
import bl07 from './../assets/blocks/bl_07.jpg'
import bl08 from './../assets/blocks/bl_08.jpg'
import br01 from './../assets/blocks/br_01.jpg'
import br02 from './../assets/blocks/br_02.jpg'
import br03 from './../assets/blocks/br_03.jpg'
import br04 from './../assets/blocks/br_04.jpg'
import br06 from './../assets/blocks/br_06.jpg'
import main01 from './../assets/blocks/main_01.svg'
import main02 from './../assets/blocks/main_02.png'
import homeBlocks from './../assets/home-blocks.png'
import logo from './../assets/logo.svg'
import LangSwitcher from './LangSwitcher'
import useWindowSize from './../hooks/useWindowSize'
import content from './../data/content.json'
import parse from 'html-react-parser'
import Sound from './Sound'
import { Howl } from 'howler'
import transitionSound from '../assets/sounds/sound_transition.wav'
import OrientationOverlay from './OrientationOverlay'

const Container = styled(motion.div)`
	${tw`h-full w-full fixed top-0 bottom-0 overflow-hidden`}
`
const Wrap = styled.div`
	${tw`flex flex-col-reverse md:flex-row justify-between h-full`}
`
const Logo = styled(motion.div)`
	${tw`absolute w-28 md:w-40 md:max-w-none z-50 right-2.5 md:right-10 top-1.5 md:top-6`}
`
const Headline = styled.h1`
	${tw`text-2xl md:text-5xl font-bold text-black mb-4 xs:mb-8`}
`
const Description = styled.p`
	${tw`text-xs md:text-base text-black mb-4 xs:mb-8`}
`

const Main = styled(motion.div)`
	${tw`h-1/2 md:h-auto w-full flex flex-col justify-center relative bg-white py-10 md:py-0`}

	& .content {
		${tw`w-full px-5 md:px-0 xs:w-4/5 sm:w-3/5 mx-auto text-center md:text-left relative z-10`}

		h1, p {
			${tw`text-content`}
		}
	}

	& .legals {
		${tw`absolute text-center md:text-left w-full md:w-3/5 left-1/2 transform -translate-x-1/2 bottom-3 md:bottom-10 text-9px xs:text-xs text-darkGray`}

		a:first-of-type {
			${tw`px-4 md:px-0 md:pr-4 border-r border-black`}
		}

		a:not(:first-of-type) {
			${tw`px-4 `}
		}
	}
	& .main_01 {
		${tw`hidden md:block absolute top-0 left-0`}
	}
	& .main_02 {
		${tw`hidden md:block absolute bottom-0 right-0`}
	}
`

const LeftBar = styled(motion.div)`
	${tw`hidden lg:grid lg:grid-rows-8 xl:grid-rows-4 col-span-2 w-full h-full max-w-max`}

	& img {
		${tw`h-full w-auto`}

		@supports (-moz-appearance: none) {
			${tw`w-full`}
		}
	}
`
const RightBar = styled(motion.div)`
	${tw`h-1/2 flex md:flex-col justify-center md:h-full`}
	min-width: 40%;

	& .blocks {
		background-color: #f3f3f4;
		height: calc(100% - 3rem);
		${tw`w-full md:h-full mt-auto md:mt-0 flex items-center justify-center flex-shrink`}

		img {
			${tw`max-h-full`}
		}
	}
	& .horizontal-blocks {
		height: calc(100% - 3rem);
		@supports (-moz-appearance: none) {
			width: 15vh;
		}
		${tw`md:w-full md:h-full md:h-auto ml-auto mr-auto md:mr-auto md:ml-0 mt-auto grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 2xl:grid-cols-4 2k:grid-cols-5`}

		img {
			${tw`max-w-none md:max-w-full w-auto h-full md:h-auto`}
		}
	}
`
const RightBarMobileSpacer = styled(motion.div)`
	${tw`md:hidden fixed bg-white w-full h-12`}
`

const Welcome = ({ gameInit }) => {
	const stateContext = useStateContext()
	const { playSound, setFinalScreen, setCardDecks } = useStateContext()
	const { locale } = stateContext.state
	const t = content.langs
	const { isMobile, width, height, device, orientation } = useWindowSize()
	const squareRef = useRef()
	const rightSquareRef = useRef()

	const onStartGameClick = useCallback(() => {
		setCardDecks(parseInt(Math.random() * 17 + 1))
		setFinalScreen(parseInt(Math.random() * content.endscreen.quantity + 1))
		stateContext.dispatch({ action: 'SET_ORIENTATION_SCREEN', data: orientation })
		stateContext.dispatch({ action: 'SET_GAME_STATUS', data: 'gameStart' })
		if (stateContext.state.sound) {
			playSound('transition')
		}
	}, [stateContext, orientation]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (stateContext.state.sounds.transition === null) {
			const newSound = new Howl({
				src: [transitionSound],
				volume: 0.5,
			})

			stateContext.dispatch({ action: 'SET_SOUND_FILE', data: 'transition', src: newSound })
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const leftBarSize = () => {
		const leftBarContainer = squareRef.current
		const imgSize = leftBarContainer.querySelector('img').getBoundingClientRect().height
		leftBarContainer.style.width = imgSize * 2 + 'px'
	}
	const rightSquaresSize = () => {
		const rightSquaresContainer = rightSquareRef.current
		if (isMobile) {
			const imgSize = rightSquaresContainer.querySelector('img').getBoundingClientRect().height
			rightSquaresContainer.style.width = imgSize + 'px'
		} else {
			rightSquaresContainer.style.width = '100%'
		}
	}

	useEffect(() => {
		leftBarSize()
		rightSquaresSize()
	});

	const leftBarVariants = {
		animate: {
			transition: {
				type: 'tween',
				duration: 0.7,
				staggerChildren: 0.1,
			},
		},
		exit: {
			transition: {
				type: 'tween',
				duration: 0.3,
				staggerChildren: 0.1,
			},
		},
	}
	const leftBarItemsVariants = {
		init: {
			x: '-100%',
		},
		animate: {
			x: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			x: '-100%',
			transition: {
				duration: 0.4,
			},
		},
	}
	const mainVariants = {
		init: {
			y: isMobile ? '100%' : '-100%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: isMobile ? '100%' : '-100%',
			transition: {
				duration: 0.4,
			},
		},
	}
	const spacerVariants = {
		init: {
			y: '-150%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: '-150%',
			transition: {
				duration: 0.4,
			},
		},
	}
	const rightBarMainVariants = {
		init: {
			x: isMobile ? '-100%' : '100%',
		},
		animate: {
			x: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			x: isMobile ? '-100%' : '100%',
			transition: {
				duration: 0.4,
			},
		},
	}
	const rightBarBottomVariants = {
		animate: {
			transition: {},
		},
		exit: {
			transition: {
				duration: 0.4,
				staggerChildren: 0.1,
			},
		},
	}
	const rightBarBottomItemsVariants = {
		init: {
			y: !isMobile ? '100%' : '0%',
			x: isMobile ? '100%' : '0%',
		},
		animate: {
			y: !isMobile ? '0%' : '0%',
			x: isMobile ? '0%' : '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: !isMobile ? '100%' : '0%',
			x: isMobile ? '100%' : '0%',
			onAnimationEnd: () => window.scrollTo(0, 0),
			transition: {
				duration: 0.4,
			},
		},
	}

	return (
		<Container>
			<Sound mode='home' mobile={true} />
			<LangSwitcher mode='mobile' />
			<Wrap>
				<LeftBar ref={squareRef} className='' variants={leftBarVariants} initial='init' animate='animate' exit='exit'>
					<motion.img variants={leftBarItemsVariants} src={bl01} alt='' />
					<motion.img variants={leftBarItemsVariants} src={bl02} alt='' />
					<motion.img variants={leftBarItemsVariants} src={bl03} alt='' />
					<motion.img variants={leftBarItemsVariants} src={bl04} alt='' />
					<motion.img className='xl:hidden' variants={leftBarItemsVariants} src={bl05} alt='' />
					<motion.img className='xl:hidden' variants={leftBarItemsVariants} src={bl06} alt='' />
					<motion.img className='xl:hidden' variants={leftBarItemsVariants} src={bl07} alt='' />
					<motion.img className='xl:hidden' variants={leftBarItemsVariants} src={bl08} alt='' />
				</LeftBar>
				<Main variants={mainVariants} initial='init' animate='animate' exit='exit'>
					<img className='main_01' src={main01} alt='' />
					<img className='main_02' src={main02} alt='' />
					<Sound mode='home' />
					<LangSwitcher />
					<div className='content'>
						<Headline>
							{width < 768 ? parse(t[locale].headline[1]) : parse(t[locale].headline[0])}
							<span className='ml-1 text-base md:text-2xl align-top'>®</span>
						</Headline>
						<Description>{parse(t[locale].description)}</Description>
						<button className='bg-blue text-white md:text-lg py-2 md:py-4 px-4 rounded-xl uppercase md:tracking-wider min-w-[180px] md:min-w-[240px]' onClick={() => onStartGameClick()}>
							{t[locale].buttonStart}
						</button>
					</div>
					<div className='legals'>
						<a href={t[locale].DatenschutzLink} target='_blank' rel='noopener noreferrer'>
							{t[locale].DatenschutzText}
						</a>
						<a href={t[locale].ImpressumLink} target='_blank' rel='noopener noreferrer'>
							{t[locale].ImpressumText}
						</a>
					</div>
				</Main>
				<RightBar>
					<RightBarMobileSpacer variants={spacerVariants} initial='init' animate='animate' exit='exit' />
					<motion.div className='blocks' variants={rightBarMainVariants} initial='init' animate='animate' exit='exit'>
						<img src={homeBlocks} alt='' />
					</motion.div>
					<motion.div ref={rightSquareRef} variants={rightBarBottomVariants} initial='init' animate='animate' exit='exit' className='horizontal-blocks'>
						<motion.img variants={rightBarBottomItemsVariants} src={br01} alt='' />
						<motion.img variants={rightBarBottomItemsVariants} src={br02} alt='' />
						<motion.img className='hidden 2xl:block' variants={rightBarBottomItemsVariants} src={br03} alt='' />
						<motion.img className='hidden 2k:block' variants={rightBarBottomItemsVariants} src={br04} alt='' />
						<motion.img variants={rightBarBottomItemsVariants} src={br06} alt='' />
					</motion.div>
				</RightBar>
			</Wrap>
			<Logo variants={spacerVariants} initial='init' animate='animate' exit='exit'>
				<img src={logo} alt='' />
			</Logo>
			{device && height < 550 && <OrientationOverlay key='orientation' />}
		</Container>
	)
}

export default Welcome
