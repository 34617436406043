import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '../context/stateContext'
import bl01 from './../assets/blocks/bl_01.jpg'
import bl02 from './../assets/blocks/bl_02.jpg'
import bl03 from './../assets/blocks/bl_03.jpg'
import bl04 from './../assets/blocks/bl_04.jpg'
import bl05 from './../assets/blocks/bl_05.jpg'
import bl06 from './../assets/blocks/bl_06.jpg'
import bl07 from './../assets/blocks/bl_07.jpg'
import bl08 from './../assets/blocks/bl_08.jpg'
import br01 from './../assets/blocks/br_01.jpg'
import br02 from './../assets/blocks/br_02.jpg'
import br06 from './../assets/blocks/br_06.png'
import main01 from './../assets/blocks/main_01.svg'
import main02 from './../assets/blocks/main_02.png'
import homeBlocks from './../assets/home-blocks.png'
import logo from './../assets/logo.svg'
import LangSwitcher from './LangSwitcher'
import content from './../data/content.json'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import useWindowSize from '../hooks/useWindowSize'
import Sound from '../components/Sound'
import OrientationOverlay from './OrientationOverlay'

const Container = styled(motion.div)`
	${tw`h-full w-full fixed top-0 bottom-0 overflow-hidden bg-white md:bg-transparent `}
`
const Wrap = styled.div`
	${tw`flex flex-col-reverse md:flex-row justify-between h-full`}
`
const Logo = styled(motion.div)`
	${tw`absolute w-28 md:w-40 md:max-w-none z-50 right-2.5 md:right-10 top-1.5 md:top-6`}
`
const Headline = styled.h1`
	${tw`text-2xl xs:text-3xl mobile:text-4xl md:text-5xl font-bold text-black mb-8 text-center`}
`
const Description = styled.p`
	${tw`text-xl text-green mb-8 text-center`}
`
const Score = styled.div`
	${tw`flex w-full justify-center mx-auto my-0 xs:my-6 md:my-10`}

	& .result {
		${tw`mx-auto border border-blue text-center w-10 h-10 xs:w-14 xs:h-14 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-xl flex items-center justify-center text-blue text-lg font-semibold md:text-2xl`}

		&-wrap {
			${tw`flex flex-col items-center mx-3`}

			.label {
				${tw`uppercase text-darkGray text-xs font-semibold mt-3`}
			}
		}
	}
`
const ButtonWrap = styled.div`
	${tw`grid grid-cols-1 md:grid-cols-2 gap-3`}
`

const Main = styled(motion.div)`
	${tw`h-3/5 md:h-auto w-full flex flex-col justify-center relative bg-white py-10 md:py-0`}

	& .content {
		${tw`w-full px-5 md:px-0 md:w-4/5 xl:w-3/5 mx-auto text-center md:text-left z-10`}

		h1, p {
			${tw`text-content`}

			@media screen and (max-width: 350px) {
				${tw`mb-4`}
			}
		}

		p {
			${tw`text-lg md:text-xl text-green leading-normal`}
		}
	}

	& .legals {
		${tw`hidden md:block absolute w-3/5 left-1/2 transform -translate-x-1/2 bottom-10 text-xs text-darkGray z-10`}

		a:first-of-type {
			${tw`pr-6 border-r border-black`}
		}

		a:not(:first-of-type) {
			${tw`px-6`}
		}
	}
	& .main_01 {
		${tw`hidden md:block absolute top-0 left-0 z-0`}
	}
	& .main_02 {
		${tw`hidden md:block absolute bottom-0 right-0 z-0`}
	}
`

const LeftBar = styled(motion.div)`
	${tw`hidden lg:grid lg:grid-rows-8 xl:grid-rows-4 col-span-2 w-full h-full max-w-max`}

	@supports ( -moz-appearance:none ) {
		@media screen and (max-width: 1279px) {
			width: 25vh;
		}
		width: 55vh;
	}

	& img {
		${tw`h-full w-auto`}
	}
`
const RightBar = styled(motion.div)`
	${tw`h-2/5 md:h-1/2 flex md:flex-col justify-center md:h-full`}
	min-width: 40%;

	& .blocks {
		${tw`md:bg-veryLightGrey w-4/5 md:w-full h-full flex items-center justify-center`}

		img {
			${tw`h-2/3 md:h-auto`}
		}
	}
	& .horizontal-blocks {
		${tw`hidden h-full md:h-auto ml-auto md:ml-0 mt-auto md:grid grid-rows-3 md:grid-rows-1 md:grid-cols-3`}

		img {
			${tw`w-auto h-full md:h-auto`}
		}
	}
`

const EndScreen = ({ gameInit }) => {
	const stateContext = useStateContext()
	const { setCardDecks, setFinalScreen } = useStateContext()
	const { locale } = stateContext.state
	const t = content.langs
	const { isMobile, height, device } = useWindowSize()

	const onRestartApp = () => {
		stateContext.dispatch({ action: 'SET_GAME_STATUS', data: 'beforeInit' })
		stateContext.dispatch({ action: 'RESET_GAME' })
	}
	const onRestartGame = async () => {
		setCardDecks(parseInt(Math.random() * 17 + 1))
		stateContext.dispatch({ action: 'SET_GAME_STATUS', data: 'gameStart' })
		await new Promise((resolve) =>
			setTimeout(() => {
				return resolve()
			}, 600)
		)
		stateContext.dispatch({ action: 'RESET_GAME' })
		setFinalScreen(parseInt(Math.random() * content.endscreen.quantity + 1))
	}

	const leftBarVariants = {
		animate: {
			transition: {
				type: 'tween',
				duration: 3,
				staggerChildren: 0.1,
			},
		},
		exit: {
			transition: {
				type: 'tween',
				duration: 3,
				staggerChildren: 0.1,
			},
		},
	}
	const leftBarItemsVariants = {
		init: {
			x: '-150%',
		},
		animate: {
			x: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			x: '-150%',
			transition: {
				duration: 0.7,
			},
		},
	}
	const mainVariants = {
		init: {
			y: isMobile ? '150%' : '-150%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: isMobile ? '150%' : '-150%',
			transition: {
				duration: 0.7,
			},
		},
	}
	const rightBarMainVariants = {
		init: {
			x: '150%',
		},
		animate: {
			x: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			x: '150%',
			transition: {
				duration: 0.7,
			},
		},
	}
	const rightBarBottomVariants = {
		animate: {
			transition: {},
		},
		exit: {
			transition: {
				staggerChildren: 0.15,
				duration: 0.7,
			},
		},
	}
	const rightBarBottomItemsVariants = {
		init: {
			y: '150%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: '150%',
			transition: {
				duration: 0.7,
			},
		},
	}
	const spacerVariants = {
		init: {
			y: '-150%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: '-150%',
			transition: {
				duration: 0.4,
			},
		},
	}

	return (
		<Container>
			<Sound mode='home' mobile={true} />
			<LangSwitcher mode='mobile' />
			<Wrap>
				<LeftBar className='' variants={leftBarVariants} initial='init' animate='animate' exit='exit'>
					<motion.img variants={leftBarItemsVariants} src={bl01} alt='' />
					<motion.img variants={leftBarItemsVariants} src={bl02} alt='' />
					<motion.img variants={leftBarItemsVariants} src={bl03} alt='' />
					<motion.img variants={leftBarItemsVariants} src={bl04} alt='' />
					<motion.img className='xl:hidden' variants={leftBarItemsVariants} src={bl05} alt='' />
					<motion.img className='xl:hidden' variants={leftBarItemsVariants} src={bl06} alt='' />
					<motion.img className='xl:hidden' variants={leftBarItemsVariants} src={bl07} alt='' />
					<motion.img className='xl:hidden' variants={leftBarItemsVariants} src={bl08} alt='' />
				</LeftBar>
				<Main variants={mainVariants} initial='init' animate='animate' exit='exit'>
					<Sound mode='home' />
					<LangSwitcher />
					<div className='content'>
						<Headline>{parse(t[locale].endHeadline)}</Headline>
						<Score>
							<div className='result-wrap'>
								<div className='result'>{stateContext.state.finalScore.pairs}</div>
								<p className='label'>{stateContext.state.finalScore.pairs === 1 ? t[locale].pairs[0] : t[locale].pairs[1]}</p>
							</div>
							<div className='result-wrap'>
								<div className='result'>{stateContext.state.finalScore.rounds}</div>
								<p className='label'>{stateContext.state.finalScore.rounds === 1 ? t[locale].rounds[0] : t[locale].rounds[1]}</p>
							</div>
						</Score>
						<Description>{parse(t[locale].endDescription)}</Description>
						<ButtonWrap>
							<button
								className='border border-transparent bg-blue text-white py-3 md:py-4 px-2 text-center text-xs rounded-xl uppercase tracking-wider mx-auto md:mx-0 w-[180px] md:w-auto'
								onClick={() => onRestartGame()}
							>
								{t[locale].endRestartButton}
							</button>
							<button
								className='border border-blue text-blue py-3 md:py-4 px-2 text-center text-xs rounded-xl uppercase tracking-wider mx-auto md:mx-0 w-[180px] md:w-auto'
								onClick={() => onRestartApp()}
							>
								{t[locale].ButtonBackToHome}
							</button>
						</ButtonWrap>
					</div>
					<div className='legals'>
						<a href={t[locale].DatenschutzLink} target='_blank' rel='noopener noreferrer'>
							{t[locale].DatenschutzText}
						</a>
						<a href={t[locale].ImpressumLink} target='_blank' rel='noopener noreferrer'>
							{t[locale].ImpressumText}
						</a>
					</div>
					<img className='main_01' src={main01} alt='' />
					<img className='main_02' src={main02} alt='' />
				</Main>
				<RightBar>
					<motion.div className='blocks' variants={rightBarMainVariants} initial='init' animate='animate' exit='exit'>
						<img src={stateContext.state.endScreenSrc !== null ? stateContext.state.endScreenSrc : homeBlocks} alt='' />
					</motion.div>
					<motion.div variants={rightBarBottomVariants} initial='init' animate='animate' exit='exit' className='horizontal-blocks'>
						<motion.img variants={rightBarBottomItemsVariants} src={br01} alt='' />
						<motion.img variants={rightBarBottomItemsVariants} src={br02} alt='' />
						<motion.img variants={rightBarBottomItemsVariants} src={br06} alt='' />
					</motion.div>
				</RightBar>
			</Wrap>
			<Logo variants={spacerVariants} initial='init' animate='animate' exit='exit'>
				<img src={logo} alt='' />
			</Logo>
			{device && height < 550 && <OrientationOverlay key='orientation' />}
		</Container>
	)
}

export default EndScreen
