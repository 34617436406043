import { useState, useEffect } from 'react'

function useWindowSize() {
	const checkOrientation = () => {
		if (window.screen?.orientation?.angle === undefined) return window.matchMedia('(orientation: portrait)').matches
		return window.screen.orientation.angle
	}
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
		isMobile: window.innerWidth > 768 ? false : true,
		device: !!navigator.maxTouchPoints ? 'mobile' : 'computer',
		orientation: checkOrientation() ? 'portrait' : 'landscape',
	})
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
				isMobile: window.matchMedia('(min-width: 768px)').matches ? false : true,
				device: !!navigator.maxTouchPoints ? 'mobile' : 'computer',
				orientation: checkOrientation() ? 'portrait' : 'landscape',
			})
		}

		window.addEventListener('resize', handleResize)

		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])
	return windowSize
}

export default useWindowSize
