import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '../context/stateContext'
import content from './../data/content.json'
import exitIcon from './../assets/exit.svg'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
	${tw`fixed left-0 right-0 -top-10 bottom-0 z-10 px-5`}
	background: rgba(0,0,0,0.25);
`
const Wrap = styled.div`
	${tw`absolute flex flex-col justify-center items-center top-1/2 left-5 right-5 sm:right-auto sm:left-1/2 bg-white transform sm:-translate-x-1/2 -translate-y-1/2 sm:w-[440px] min-h-[460px] rounded-xl px-8 sm:px-0`}
`
const ButtonExit = styled.p`
	${tw`absolute top-4 right-4 sm:top-8 sm:right-8 cursor-pointer`}
`
const Text = styled.p`
	${tw`w-full sm:w-4/5 text-center text-xl sm:text-2xl text-content font-semibold mx-auto my-5 sm:my-10 sm:px-6`}
`
const ButtonBackToGame = styled.button`
	${tw`w-full sm:w-3/5 rounded-xl bg-blue text-center text-lg font-semibold tracking-wider text-white uppercase p-4 border border-transparent mb-5 cursor-pointer`}
`
const ButtonBackToHome = styled.button`
	${tw`w-full sm:w-3/5 rounded-xl text-center text-lg font-semibold text-blue tracking-wider uppercase mb-5 p-4 border border-blue cursor-pointer`}

	a {
		${tw`inline-block p-4 w-full`}
	}
`

const Popup = ({ toggle }) => {
	const stateContext = useStateContext()
	const { locale } = stateContext.state
	const t = content.langs

	const popupVariants = {
		init: {
			opacity: 0,
			visibility: 'hidden',
			y: 20,
			transition: {
				duration: 0.7,
				type: 'spring',
			},
		},
		show: {
			opacity: 1,
			visibility: 'visible',
			y: 0,
			transition: {
				duration: 0.7,
				type: 'spring',
			},
		},
		exit: {
			y: 20,
			opacity: 0,
			transitionEnd: {
				visibility: 'hidden',
			},
			transition: {
				duration: 0.7,
				type: 'spring',
			},
		},
	}

	return (
		<Container variants={popupVariants} initial='init' animate='show' exit='exit'>
			<Wrap>
				<ButtonExit onClick={() => stateContext.dispatch({ action: 'SET_POPUP_STATUS', data: false })}>
					<img src={exitIcon} alt='' />
				</ButtonExit>
				<Text>{t[locale].PopupText}</Text>

				<ButtonBackToGame onClick={() => stateContext.dispatch({ action: 'SET_POPUP_STATUS', data: false })}>{t[locale].ButtonBackToGame}</ButtonBackToGame>
				<ButtonBackToHome
					onClick={() => {
						stateContext.dispatch({ action: 'SET_POPUP_STATUS', data: false })
						stateContext.dispatch({ action: 'SET_GAME_STATUS', data: 'beforeInit' })
					}}
				>
					{t[locale].ButtonBackToHome}
				</ButtonBackToHome>
			</Wrap>
		</Container>
	)
}

export default Popup
