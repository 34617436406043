import React from 'react'
import tw, { styled } from 'twin.macro'
import { motion } from 'framer-motion'
import cardsData from './../data/cards.json'
import { useStateContext } from '../context/stateContext'
import useWindowSize from '../hooks/useWindowSize'

const CardSlot = styled(motion.div)`
	${tw`w-14 h-14 xs:w-16 xs:h-16 mobile:w-20 mobile:h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 2xl:w-44 2xl:h-44 `}
	@media (min-width: 1536px) and (max-height: 750px) {
		${tw`2xl:w-40 2xl:h-40`}
	}
	@media (min-width: 1536px) and (max-height: 700px) {
		${tw`2xl:w-36 2xl:h-36`}
	}
	@media (min-width: 1536px) and (max-height: 650px) {
		${tw`2xl:w-32 2xl:h-32`}
	}
`
const CardItem = styled(motion.div)`
	${tw`absolute w-14 h-14 xs:w-16 xs:h-16 mobile:w-20 mobile:h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 2xl:w-44 2xl:h-44 cursor-pointer`}
	perspective: 800px;
	transform-style: preserve-3d;
	@media (min-width: 1536px) and (max-height: 750px) {
		${tw`2xl:w-40 2xl:h-40`}
	}
	@media (min-width: 1536px) and (max-height: 700px) {
		${tw`2xl:w-36 2xl:h-36`}
	}
	@media (min-width: 1536px) and (max-height: 650px) {
		${tw`2xl:w-32 2xl:h-32`}
	}
`
const CardFront = styled(motion.div)`
	${tw`absolute w-14 h-14 xs:w-16 xs:h-16 mobile:w-20 mobile:h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 2xl:w-44 2xl:h-44 rounded-xl flex items-center justify-center bg-contain transform-gpu`}
	transform-style: preserve-3d;
	perspective: 800px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.45);
	backface-visibility: hidden;
	transform-origin: 50% 50%;
	@media (min-width: 1536px) and (max-height: 750px) {
		${tw`2xl:w-40 2xl:h-40`}
	}
	@media (min-width: 1536px) and (max-height: 700px) {
		${tw`2xl:w-36 2xl:h-36`}
	}
	@media (min-width: 1536px) and (max-height: 650px) {
		${tw`2xl:w-32 2xl:h-32`}
	}

	&::after {
		content: '';
		top: 4px;
		left: 4px;
		right: 4px;
		bottom: 4px;
		${tw`absolute rounded-xl`}
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
	}
`
const CardBehind = styled(motion.div)`
	${tw`absolute w-14 h-14 xs:w-16 xs:h-16 mobile:w-20 mobile:h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 2xl:w-44 2xl:h-44 rounded-xl flex items-center justify-center bg-contain transform-gpu`}
	transform-style: preserve-3d;
	perspective: 800px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.45);
	backface-visibility: hidden;
	transform-origin: 50% 50%;
	@media (min-width: 1536px) and (max-height: 750px) {
		${tw`2xl:w-40 2xl:h-40`}
	}
	@media (min-width: 1536px) and (max-height: 700px) {
		${tw`2xl:w-36 2xl:h-36`}
	}
	@media (min-width: 1536px) and (max-height: 650px) {
		${tw`2xl:w-32 2xl:h-32`}
	}

	&::after {
		content: '';
		top: 4px;
		left: 4px;
		right: 4px;
		bottom: 4px;
		${tw`absolute rounded-xl`}
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
	}
`

const Card = ({ order, index, cardClickHandle, foldedPairs, toFold, isPair, notPair, firstCardSelected, secondCardSelected, uniqueKey, reversDeckSrc, flipDeckSrc }) => {
	const { isMobile, width } = useWindowSize()
	const { animationState } = useStateContext()
	const onCardClick = () => {
		if (foldedPairs.includes(index)) return
		if (!animationState && !isPair && uniqueKey !== firstCardSelected.key && secondCardSelected.key === '') {
			cardClickHandle(index, uniqueKey)
		}
		if (notPair && !animationState) cardClickHandle()
	}

	const transition = {
		delay: 0.5,
		duration: 0.5,
		ease: 'easeInOut',
		type: 'tween',
	}
	const firstCardVariants = {
		initial: {
			y: 0,
			x: 0,
			scale: 1,
			transformOrigin: '50% 50%',
			zIndex: 49,
		},
		animate: {
			zIndex: 999,
			scale: isMobile ? 1.4 : 1.2,
			y: width < 1536 ? '-150%' : '-130%',
			x: isMobile ? '-130%' : '-125%',
			left: '50%',
			top: '50%',
			transition: transition,
		},
		folded: {
			zIndex: 999,
			scale: 0,
			y: 0,
			x: '-100%',
			top: '90%',
			left: '50%',
			transition: {
				duration: 0.3,
				ease: 'easeIn',
			},
		},
	}
	const secondCardVariants = {
		initial: {
			y: 0,
			x: 0,
			scale: 1,
			transformOrigin: '50% 50%',
			zIndex: 49,
		},
		animate: {
			zIndex: 999,
			scale: isMobile ? 1.4 : 1.2,
			y: width < 1536 ? '-150%' : '-130%',
			x: isMobile ? '25%' : '20%',
			left: '50%',
			top: '50%',
			transition: transition,
		},
		folded: {
			zIndex: 999,
			scale: 0,
			y: 0,
			x: '-100%',
			top: '90%',
			left: '50%',
			transition: {
				duration: 0.3,
				ease: 'easeIn',
			},
		},
	}
	const flipAnimation = {
		initial: {
			rotateY: 0,
			scale: 1,
			transition: {
				ease: [0.4, 0.2, 0.2, 1],
				duration: 0.7,
			},
		},
		flipped: {
			rotateY: 180,
			scale: [1, 1.4],
			transition: {
				ease: [0.4, 0.2, 0.2, 1],
				duration: 0.7,
			},
		},
		flippedReverse: {
			rotateY: -180,
			scale: [1, 1.4],
			transition: {
				ease: [0.4, 0.2, 0.2, 1],
				duration: 0.7,
			},
		},
	}

	return (
		<CardSlot
			data-index={index}
			data-unique={uniqueKey}
			onClick={onCardClick}
			style={{
				order: order,
			}}
		>
			{!foldedPairs.includes(index) && (
				<CardItem
					initial='initial'
					animate={firstCardSelected.pair === index && isPair && !toFold ? 'animate' : toFold && secondCardSelected.pair === index && isPair ? 'folded' : 'initial'}
					variants={firstCardSelected.key === uniqueKey ? firstCardVariants : secondCardVariants}
				>
					<CardFront
						initial='initial'
						style={{
							backgroundImage: `url(${reversDeckSrc})`,
							WebkitBackfaceVisibility: 'hidden',
						}}
						animate={firstCardSelected.key === uniqueKey || secondCardSelected.key === uniqueKey ? 'flipped' : 'initial'}
						variants={flipAnimation}
						transition={{ duration: 1 }}
					></CardFront>
					<CardBehind
						initial='flippedReverse'
						style={{
							backgroundImage: `url(${flipDeckSrc})`,
							WebkitBackfaceVisibility: 'hidden',
						}}
						animate={firstCardSelected.key === uniqueKey || secondCardSelected.key === uniqueKey ? 'initial' : 'flippedReverse'}
						variants={flipAnimation}
						transition={{ duration: 1 }}
					>
						{cardsData[index - 1].frontUrlDE}
					</CardBehind>
				</CardItem>
			)}
		</CardSlot>
	)
}

export default Card
