import Screen from './components/Screen'
import StateContextProvider from './context/stateContext'
import { Redirect, Route, Switch } from "react-router-dom"

function App() {
	return (
		<Switch>
			<Route
				exact
				path='/'
				render={(props) => (
					<StateContextProvider query={ props.location.search }>
						<div className='app'>
							<Screen />
						</div>
					</StateContextProvider>
				)} />
			<Route>
				<Redirect to={'/'} />
			</Route>
		</Switch>
	)
}

export default App
